
.cookie-settings-container {
    text-align: left;
    max-width: min(500px, 95vw);
}

.cookie-settings-label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.9rem;
}

.cookie-settings-label > svg {
    font-size: 0.9rem;
}

.cookie-settings-arrow {
    cursor: pointer;
    transition: transform ease-in 300ms;
}

.cookie-settings-buttons-container {
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
}

/* Why important here: https://github.com/Mastermindzh/react-cookie-consent/issues/20*/
/*TLDR, For me to not use important here, I'd have to disable the entire styling of the lib and
    do it myself from scratch.
 */
.cookie-buttons:hover,
.cookie-buttons:focus {
    background: #d5d5d5 !important;
}