.our-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.popup-base {
    max-width: min(1000px, 95vw);
    max-height: 95vh;
    display: flex;
    justify-content: center;
}