@use "../index";
@use "Layout";

[data-theme="dark"] {
  .bordered-default-text {
    background-color: index.$clr-blue-grey;
  }
}

.clr-primary-green {
  color: index.$clr-primary
}

.clr-secondary-green {
  color: index.$clr-secondary;
}

.clr-yellow {
  color: index.$clr-yellow;
}

.clr-orange {
  color: index.$clr-orange;
}

.clr-red {
  color: index.$clr-red;
}

.clr-purple {
  color: index.$clr-purple;
}

.clr-primary-blue {
  color: index.$clr-primary-blue
}

.clr-secondary-blue {
  color: index.$clr-secondary-blue;
}

.clr-primary-blue-light {
  color: index.$clr-primary-blue-light;
}

.clr-secondary-blue-light {
  color: index.$clr-secondary-blue-light;
}

[data-theme="dark"] {
  .clr-grey {
    color: grey;
  }
}

.clr-grey {
  color: index.$clr-grey;
}

.clr-black {
  color: index.$clr-black;
}

.clr-white {
  color: #FFFFFFFF;
}

.bold {
  font-weight: index.$font-bold;
}

.semi-bold {
  font-weight: index.$font-semi-bold;
}

.regular {
  font-weight: index.$font-regular;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.font-size-h6 {
  font-size: index.$mdc-typography-styles-headline6;
}

.pointer {
  cursor: pointer;
}

$clr-grey-filter: invert(42%) sepia(10%) saturate(22%) hue-rotate(319deg) brightness(95%) contrast(90%);
$clr-ice-blue-filter: invert(93%) sepia(8%) saturate(239%) hue-rotate(170deg) brightness(90%) contrast(88%);
$clr-primary-green-filter: invert(56%) sepia(13%) saturate(2268%) hue-rotate(77deg) brightness(103%) contrast(84%);

[data-theme="dark"] {
  .default-icon-button-base {
    filter: $clr-ice-blue-filter;
  }
}

.default-icon-button-base {
  filter: $clr-grey-filter;
}

.primary-icon-button-base {
  filter: $clr-primary-green-filter;
}


.request-form-icon-small {
  @extend .default-icon-button-base;
  width: 15px;
  height: 15px;
}

.request-form-icon-large {
  @extend .default-icon-button-base;
  margin: 4.85px 4.85px 4.85px 2px;
  width: 24px;
  height: 24px;
}

.share-icon {
  width: 15px;
  height: 15px;
  filter: invert(56%) sepia(21%) saturate(1295%) hue-rotate(77deg) brightness(100%) contrast(98%);
}

.lock-icon {
  @extend .default-icon-button-base;
}

@mixin glow($overflow) {
  content: '';
  position: absolute;
  top: $overflow;
  bottom: $overflow;
  left: $overflow;
  right: $overflow;
  border-radius: 10px;
  background: hsla(128, 52%, 47%, 0.55);
  z-index: -100;
  filter: blur(7px);
}

.link {
  text-decoration: underline;
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;
}

.none-selectable {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.h4point5 {
  font-size: 39.45px;
  line-height: 1.35;
  letter-spacing: 0.25px;
}

.h5point5 {
  font-size: 26.7px;
  line-height: 1.45;
  letter-spacing: 0;
}

.h6point5 {
  font-size: 21.15px;
  line-height: 1.45;
  letter-spacing: 0.15px;
}

.body1Point5 {
  font-size: 17.3px;
  letter-spacing: 0.5px;
}

[data-theme="dark"] {
  .eden-logo-fill {
    fill: white;
  }
}

.eden-logo-fill {
  fill: black;
}

.status-label {
  border-radius: 100px;
  color: white;
  padding: 0.2rem 0.45rem;
  display: inline-flex;
}

@mixin waitlist-label {
  @extend .status-label;
  background-color: index.$clr-yellow;
}

.waitlist-label {
  @include waitlist-label;
}

@mixin closed-label {
  @extend .status-label;
  background-color: index.$clr-red;
}

.closed-label {
  @include closed-label;
}

.onboard-welcome-msg {
  font-size: 35px;
}

.bordered-default-text {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  padding-left: Layout.$horizontal-padding-constant * 2;
  padding-right: Layout.$horizontal-padding-constant * 2;
  position: relative;
  overflow: hidden;
}

.light-bordered-default-text {
  @extend .bordered-default-text;
  border-radius: 10px;
}