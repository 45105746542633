@use "./index";

.app-content-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover,
.grow:focus {
  transform: scale(1.1);
}