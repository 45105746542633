.loading-container {
  /*subtract header height*/
  height: calc(100vh - 94.4px);
}

.loading-logo {
  margin-top: auto;
  width: 200px;
  height: 200px;
}

.loading-text {
  margin-bottom: auto;
}

$loading-animation: fade-in 1.5s ease-in-out infinite;

.loading-container {
  g#leaf-2, g#leaf-3, g#leaf-4, g#leaf-5, g#leaf-6, .loading-text {
    opacity: 1;
  }

  @keyframes fade-in {
    75% {
      opacity: 0;
    }
  }

  g#leaf-2 {
    animation: $loading-animation;
    animation-delay: 0.25s;
  }

  g#leaf-3 {
    animation: $loading-animation;
    animation-delay: 0.5s;
  }

  g#leaf-4 {
    animation: $loading-animation;
    animation-delay: 0.75s;
  }

  g#leaf-5 {
    animation: $loading-animation;
    animation-delay: 1s;
  }

  g#leaf-6 {
    animation: $loading-animation;
    animation-delay: 1.25s;
  }
}

.loading-text {
  animation: $loading-animation;
  margin-left: auto;
  margin-right: auto;
}