@use "../../index";

[data-theme="dark"] {
  .paper-base {
    background-color: index.$clr-black-secondary;
  }

  .paper-modal-close-icon {
    color: index.$clr-ice-blue;
  }

  .paper-modal-nav-bar {
    background-color: hsla(216, 28%, 7%, 0.65);
  }
}

$modal-inline-padding: 1rem;

.paper-base {
  background-color: white;
  border-radius: 10px;
  overflow-x: hidden;
  padding: 0 $modal-inline-padding 1.5rem $modal-inline-padding;
}

.paper-modal-nav-bar {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  backdrop-filter: blur(12px);
  background-color: hsla(0, 0%, 100%, 0.65);
  margin-left: -$modal-inline-padding;
  overflow: hidden;
  position: sticky;
  top: 0;
  width: calc(100% + ($modal-inline-padding * 2));
  z-index: 10;
}

.paper-modal-left-side-element {
  padding-left: 0.5rem;
}

.paper-modal-close-icon {
  align-self: flex-start;
  cursor: pointer;
  color: #231F20;
  margin-left: auto;
  z-index: 10;
}
