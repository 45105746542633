@use "../../index";

.message-box-medium-container {
  padding-left: 10%;
  padding-right: 10%;
  padding-block: 1.5rem;
  width: 100%;
}

.message-box-small-container {
  @extend .message-box-medium-container;
  padding-left: 5%;
  padding-right: 5%;
  padding-block: 1rem;
}

.message-box-medium-text {
  // in between an h5 and h6
  font-size: 26.7px;
  line-height: 1.45;
  letter-spacing: 0;
}

.message-box-small-text {
  font-size: index.$mdc-typography-styles-headline6;
  letter-spacing: 0.15px;
}

.base-box {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  position: relative;
  color: white;
}

.stroke {
  position: absolute;
  height: 120%;
  width: 125%;
  left: -12.5%;
  top: -10%;
  z-index: -1;
}


@media (max-width: index.$media-tablet) {
  .stroke {
    width: 120%;
    left: -10%;
  }

  .message-box-medium-text {
    font-size: 24px;
    max-width: 40ch;
  }
}

@media (max-width: index.$media-mobile) {
  .message-box-medium-text {
    font-size: 21px;

  }
}