@use "../../index";

$footer-text-size: 14.4px;

[data-theme="dark"] {
  .site-footer {
    --base-lightness: 47%;
    --hover-lightness: 33%;
  }

  .footer-grid-container::after {
    background-color: index.$clr-grey-border;
  }

  .footer-none-a-link {
    color: inherit;
  }
}


.site-footer {
  margin-top: 3.5rem;
  padding-bottom: 1rem;
  --base-lightness: 42%;
  --hover-lightness: 27%;
}

.footer-content-container {
  width: min(900px, 95vw);
  margin-left: auto;
  margin-right: auto;
}

.footer-grid-container {
  position: relative;
}

.theme-toggle-button {
  position: absolute;
  top: -20px;
  right: 0;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 4vw;
  row-gap: 1rem;
  padding-bottom: 1rem;
}

.footer-grid-container::after {
  display: block;
  content: " ";
  height: 1px;
  background: black;
  width: 100%;
}

.footer-logo {
  height: index.$logo-height;
  width: index.$logo-width;
}

.footer-flex-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: $footer-text-size;
}

.footer-top-padding {
  padding-top: 1.25rem;
}

.footer-flex-col > a,
.svg-footer-container > a {
  color: hsl(0 0% var(--base-lightness));
  text-decoration: none;
}

.footer-flex-col > a:hover,
.footer-flex-col > a:focus {
  color: hsl(0 0% var(--hover-lightness));
}

.footer-title {
  padding-bottom: 0.35rem;
}

.footer-svg {
  filter: brightness(var(--base-lightness));
  width: 24.5px;
}

.footer-none-a-link {
  cursor: pointer;
  color: hsl(0 0% var(--base-lightness));
}

.footer-none-a-link:hover,
.footer-none-a-link:focus {
  color: hsl(0 0% var(--hover-lightness));
}

.social-container {
  padding-left: 0.75rem;
  display: flex;
  gap: 0.25rem;
  width: fit-content;
  align-items: center;
  font-size: $footer-text-size;
}

.copyright-text {
  margin-bottom: 0;
}

.resources-container {
  position: relative;
}

.resources-container::after {
  content: "";
  position: absolute;
  background: url("../../assets/plant-trees-section-butterfly.svg") no-repeat right;
  background-size: contain;
  bottom: -4rem;
  right: 0;
  height: 30px;
  width: 37px;
  z-index: -1;
}

@media only screen and (max-width: 650px) {
  .footer-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 460px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 350px) {
  .footer-top-padding {
    padding-top: 0.25rem;
  }

  .footer-grid {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 1rem;
  }
}