@use "../../../index";

.no-fees-container {
  position: relative;
  background: transparent;
}

.no-fees-text {
  padding-top: 15%;
  flex-shrink: 2;
}

.no-fees-graphic-attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 1rem;
  padding-bottom: 1rem;
}


@media (max-width: #{index.$media-tablet}) {
  .no-fees-container > div {
    flex-direction: column;
  }
}
