.modal-base {
  display: flex;
  flex-direction: column;
}

.modal-container {
  position: relative;
  text-align: center;
  max-height: 95vh;
  overflow-y: auto;
}

.modal-multiline-inputs {
  margin-left: auto;
  margin-right: auto;
  min-width: min(55ch, 100%);
}

.modal-multiline-inputs > p {
  margin-bottom: 0.25rem;
}