@use "../../index";

.clickable-img {
  cursor: pointer;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  transition: all .2s ease-in-out;
}

.clickable-img-grow:hover, .clickable-img-grow:focus {
  transform: scale(1.05);
}

.clickable-img-grow:hover + #img-close-icon-id, .clickable-img-grow:focus + #img-close-icon-id {
  display: none;
}

.modal-img {
  max-width: min(100%, 95vw);
  max-height: 95vh;
}

.loading-error {
  border: 1px solid index.$clr-red;
  border-radius: 5px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.image-gallery-icon:hover {
  color: index.$clr-secondary !important;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none !important;
}

.image-gallery-icon:focus {
  outline: 2px solid index.$clr-secondary !important;
}

.image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery-bullets .image-gallery-bullet:focus {
  background-color: index.$clr-secondary !important;
  border: 1px solid index.$clr-secondary !important;
}