@use "../../index";
@use "../../style/Layout";

[data-theme="dark"] {
  .copy-icon-container:hover::after,
  .copy-icon-container:focus::after {
    background-color: hsl(215, 21%, 14%);
  }

  .copy-icon-container:active::after {
    background-color: hsl(215, 21%, 14%);
  }
}

.copy-icon-container {
  display: contents;
}

.copy-icon {
  max-height: 22px;
}

.copy-icon:hover,
.copy-icon:focus {
  color: hsl(0, 0%, 30%);
}

.copy-icon-container:hover::after,
.copy-icon-container:focus::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, 05%);
  border-radius: 100px;
  top: 0;
  left: 0;
  z-index: -2;
  animation: grow 250ms linear;
  transform-origin: 90% 50%;
}

.copy-icon-container:active::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 15%);
}

@keyframes grow {
  100% {
    transform: scale(1);
  }

  0% {
    transform: scale(0);
  }
}