@use "../index";

$vertical-padding-constant: 0.5rem;
$horizontal-padding-constant: 0.5rem;
$text-width: 65ch;
$small-text-width: 40ch;
$screen-padding: 95vw;

.laptop-l-screen-width {
  width: min(#{index.$media-laptop-l}, 100%);
  margin-left: auto;
  margin-right: auto;
}

.laptop-l-screen-width-padding {
  width: min(#{index.$media-laptop-l}, #{$screen-padding});
  margin-left: auto;
  margin-right: auto;
}

.laptop-screen-width-padding {
  width: min(#{index.$media-laptop}, #{$screen-padding});
  margin-left: auto;
  margin-right: auto;
}

.table-scree-width-padding {
  width: min(#{index.$media-tablet}, #{$screen-padding});
  margin-left: auto;
  margin-right: auto;
}

.form-screen-width {
  width: min(600px, #{$screen-padding});
  margin-left: auto;
  margin-right: auto;
}

.input-screen-width-no-inline {
  width: min(200px, #{$screen-padding});
}

.input-screen-width {
  width: min(225px, #{$screen-padding});
  margin-left: auto;
  margin-right: auto;
}

.max-text-width {
  max-width: $text-width;
}

.max-small-text-width {
  max-width: $small-text-width;
}

.fill-container-height {
  height: 100%;
}

.text-width {
  h1, h2, h3, h4, h5, h6, p {
    width: min(100%, #{$text-width});
  }
}

.small-text-width {
  h1, h2, h3, h4, h5, h6, p {
    width: min(100%, #{$small-text-width});
  }
}

.flex-row {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.flex-row-wrap {
  @extend .flex-row;
  flex-wrap: wrap;
}

.flex-row-wrap-no-grow {
  @extend .flex-row-wrap;
  flex-grow: 0;
}

.flex-row-gap {
  @extend .flex-row;
  gap: $horizontal-padding-constant * 2;
}

.flex-row-gap-left {
  @extend .flex-row-gap;
  align-items: flex-start;
}

.flex-row-half-gap {
  @extend .flex-row;
  gap: $horizontal-padding-constant;
}

.flex-row-half-gap-wrap {
  @extend .flex-row-half-gap;
  flex-wrap: wrap;
}

.flex-row-half-gap-wrap-no-grow {
  @extend .flex-row-half-gap-wrap;
  flex-grow: 0;
}

.flex-row-half-gap-left {
  @extend .flex-row-half-gap;
  align-items: flex-start;
}

.flex-row-gap-wrap {
  @extend .flex-row-gap;
  flex-wrap: wrap;
}

.flex-row-gap-wrap-HCenter {
  @extend .flex-row-gap-wrap;
  justify-content: center;
}

.flex-row-half-gap-wrap-HCenter {
  @extend .flex-row-half-gap-wrap;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-col-no-align {
  display: flex;
  flex-direction: column;
}

.flex-col-left {
  @extend .flex-col;
  align-items: flex-start;
}

.flex-col-right {
  @extend .flex-col;
  align-items: flex-end;
}

.flex-col-wrap {
  @extend .flex-col;
  flex-wrap: wrap;
}

.flex-col-gap {
  @extend .flex-col;
  gap: $vertical-padding-constant;
}

.flex-col-no-align-gap {
  @extend .flex-col-no-align;
  gap: $vertical-padding-constant;
}

.flex-col-double-gap {
  @extend .flex-col-gap;
  gap: $vertical-padding-constant * 2;
}

.flex-col-gap-wrap {
  @extend .flex-col-gap;
  flex-wrap: wrap;
}

.flex-col-gap-left {
  @extend .flex-col-double-gap;
  align-items: flex-start;
}

.flex-inline {
  display: inline-flex;
  align-items: center;
}

.flex-inline-half-gap {
  @extend .flex-inline;
  gap: $horizontal-padding-constant
}

.align-flex-start {
  align-items: flex-start;
}

.margin-auto-self {
  margin: auto;
}

.margin-left-auto {
  margin-left: auto;
}

.auto-inline-self {
  margin-left: auto;
  margin-right: auto;
}

.auto-block-self {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-inline > * {
  margin-left: auto;
  margin-right: auto;
}

.auto-inline > *:first-child {
  margin-left: auto;
}

.auto-inline > *:last-child {
  margin-right: auto;
}

.auto-in-between > *:first-child {
  margin-right: auto;
}

.auto-in-between > *:last-child {
  margin-left: auto;
}

.padding-inline-screen {
  padding-left: 5vw;
  padding-right: 5vw;
}

.padding-block-screen {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.padding-screen {
  @extend .padding-block-screen;
  @extend .padding-inline-screen;
}

.padding-inline-self {
  padding-left: $horizontal-padding-constant;
  padding-right: $horizontal-padding-constant;
}

.padding-top {
  padding-top: $vertical-padding-constant;
}

.padding-bottom {
  padding-bottom: $vertical-padding-constant;
}

.padding-right {
  padding-right: $horizontal-padding-constant;
}

.padding-left {
  padding-left: $horizontal-padding-constant;
}

.padding-block-self {
  padding-top: $vertical-padding-constant;
  padding-bottom: $vertical-padding-constant;
}

.padding-self {
  padding: $vertical-padding-constant * 2 $horizontal-padding-constant * 2;
}

.padding-inline > * {
  padding-left: $horizontal-padding-constant;
  padding-right: $horizontal-padding-constant;
}

.padding-block > * {
  padding-top: $vertical-padding-constant;
  padding-bottom: $vertical-padding-constant;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

ul.numbered-ul {
  li {
    list-style: decimal;
    margin: 0;
    text-indent: 0;
  }
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
}

.modal-small-text {
  position: relative;
  color: index.$clr-grey;
  text-align: left;
}

.modal-small-bottom-text {
  @extend .modal-small-text;
  bottom: -1rem;
  left: -0.45rem;
}

.modal-small-top-text {
  @extend .modal-small-text;
  top: -0.85rem;
  left: -0.65rem;
}