.our-collapse-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.our-collapse-container > * {
    margin-left: 0;
    margin-right: 0;
}