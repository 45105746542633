@use "../../index";
@use "../../style/Layout";

[data-theme="dark"] {
  .announcement-msg {
    color: index.$clr-ice-blue;
  }
}

.nav-bar {
  opacity: 0.85;
  box-shadow: none;
  padding-top: 0.5rem;
  position: relative;
  overflow: hidden;
}

.logo {
  height: index.$logo-height;
  width: index.$logo-width;
}

.nav-bar-icons {
  justify-content: flex-end;
}

.logo-small {
  display: none;
}

.announcement-msg {
  position: relative;
  border-top: 1px index.$clr-grey solid;
  padding-top: Layout.$horizontal-padding-constant / 2;
  padding-bottom: Layout.$horizontal-padding-constant / 2;
  width: 100vw;
  color: index.$clr-black;
  left: -5vw;
}

.maintenance-msg {
  position: relative;
  width: 100vw;
  padding-top: Layout.$horizontal-padding-constant / 2;
  padding-bottom: Layout.$horizontal-padding-constant / 2;
  background-color: #EA2B7B;
  left: -5vw;
}

@media (max-width: 450px) {

  .logo {
    display: none;
  }

  .logo-small {
    display: revert;
    height: index.$logo-height;
    width: 38px;
  }
}