@use "../../index";


.homepage-graphic {
  max-width: clamp(300px, 500px, 550px);
  width: auto;
  height: auto;
  max-height: 500px;
}

@media (max-width: #{index.$media-tablet}) {
  .homepage-graphic {
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
}