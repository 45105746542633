/* Eric Meyer’s reset starts  */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Eric Meyer’s reset ends */

/* Our reset starts */

*,
*:before,
*:after {
  box-sizing: border-box;
}

.app-content-container {
  .base-list-styling {
    list-style-position: inside;
    text-indent: -1.75rem;
    margin-left: 1.75rem;
  }

  ol[type="1"] > li {
    @extend .base-list-styling;
    list-style-type: decimal;
  }
}

a {
  -webkit-appearance: none;
  text-decoration: none;
  word-wrap: break-word;
}

a:hover {
  text-decoration: none;
}

/* Our reset ends */

$clr-primary: #8AC440;
$clr-secondary: #39B54A;
$clr-primary-blue-light: #02B3B1;
$clr-secondary-blue-light: #46E5F5;
$clr-primary-blue: #26A7DF;
$clr-secondary-blue: #175EE9;
$clr-yellow: #FFCB32;
$clr-orange: #F7A430;
$clr-red: #EA2B7B;
$clr-purple: #C46CF5;
$clr-grey: #6A6A6A;
$clr-black: #231F20;

$clr-ice-blue: #c9d1d9;
$clr-black-main-background: #010409;
$clr-black-secondary: #0d1117;
$clr-blue-grey: #161b22;
$clr-grey-border: #2a2e33;

$font-family: "Poppins", sans-serif;

$font-light: 300;
$font-regular: 400;
$font-semi-bold: 500;
$font-bold: 600;

$mdc-typography-styles-headline1: 92.5px;
$mdc-typography-styles-headline2: 57.8px;
$mdc-typography-styles-headline3: 46.2px;
$mdc-typography-styles-headline4: 32.7px;
$mdc-typography-styles-headline5: 23.1px;
$mdc-typography-styles-headline6: 19.2px;
$mdc-typography-styles-body1: 15.4px;
$mdc-typography-styles-body2: 13.4px;
$mdc-typography-styles-subtitle1: 15.4px;
$mdc-typography-styles-subtitle2: 13.4px;
$mdc-typography-styles-button: 13.4px;
$mdc-typography-styles-overline: 10px;
$mdc-typography-styles-caption: 12px;

$media-laptop-l: 1440px;
$media-laptop: 1024px;
$media-tablet: 768px;
$media-mobile: 426px;
$media-mobile-small: 320px;

$logo-height: 80px;
$logo-width: 170px;
$logo-width-small: 38px;

:root {
  --clr-grey: #6A6A6A;
  --clr-yellow: #FFCB32;
  --clr-black: #231F20;
  --toastify-color-success: #39B54A;
  --toastify-icon-color-error: #EA2B7B;
  --toastify-color-progress-error: #EA2B7B;
  --toastify-text-color-light: var(--clr-black);
  --toastify-color-dark: #161b22;
  --toastify-text-color-dark: #c9d1d9;
}

[data-theme="dark"] {
  background-color: $clr-black-main-background;

  body {
    color: $clr-ice-blue;
  }

  ::selection {
    background-color: hsla(198, 74%, 51%, 0.25);
  }

  a {
    color: $clr-ice-blue;
  }
}

::selection {
  background-color: hsla(198, 74%, 51%, .65);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-family;
  font-weight: $font-regular;
  line-height: 1.5;
  color: $clr-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scrollbar-width: thin;
  scrollbar-color: hsla(0, 0%, 42%, .55);
}

$scrollbar-size: 0.45em;

*::-webkit-scrollbar {
  cursor: pointer;
  width: $scrollbar-size;
  height: $scrollbar-size;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px hsla(0, 0%, 42%, .25);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 42%, .55);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:horizontal:hover,
*::-webkit-scrollbar-thumb:vertical:hover {
  /* hover thumb styles */
  background: hsla(0, 0%, 42%, .75);
}

*::-webkit-scrollbar-thumb:horizontal:active,
*::-webkit-scrollbar-thumb:vertical:active {
  /* active thumb styles */
  background: hsla(0, 0%, 42%, .75);
}

a {
  color: $clr-black;
}

h1 {
  font-size: $mdc-typography-styles-headline1;
  line-height: 1;
  letter-spacing: -1.5px;
}

h2 {
  font-size: $mdc-typography-styles-headline2;
  line-height: 1.15;
  letter-spacing: -0.5px;
}

h3 {
  font-size: $mdc-typography-styles-headline3;
  line-height: 1.25;
  letter-spacing: 0;
}

h4 {
  font-size: $mdc-typography-styles-headline4;
  line-height: 1.35;
  letter-spacing: 0.25px;
}

h5 {
  font-size: $mdc-typography-styles-headline5;
  line-height: 1.45;
  letter-spacing: 0;
}

h6 {
  font-size: $mdc-typography-styles-headline6;
  letter-spacing: 0.15px;
}

.body1 {
  font-size: $mdc-typography-styles-body1;
  letter-spacing: 0.5px;
}

.body2 {
  font-size: $mdc-typography-styles-body2;
  letter-spacing: 0.25px;
}

.caption {
  font-size: $mdc-typography-styles-caption;
  letter-spacing: 0.4px;
}

.overline {
  font-size: $mdc-typography-styles-overline;
  letter-spacing: 1.5px;
}